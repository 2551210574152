import React, { useContext } from "react";
import FbxLogo from "pages/Home/FbxLogo";
import useWindowDimensions from "hooks/useWindowDimensions";
import useIsVisible from "hooks/useIsVisible";

import CheckSvg from "./assets/section4/check.svg";
import CheckGreySvg from "./assets/section4/check-grey.svg";
import StarSvg from "./assets/star.svg";
import RudderSvg from "./assets/rudder.svg";

import "./styles.scss";
import { HomeContext } from "contexts";
import { isAndroid, isIOS } from "react-device-detect";

function Section4() {
  const { elementRef } = useIsVisible("ფასი");
  const { width } = useWindowDimensions();
  const isMobile = width <= 840 || isIOS || isAndroid;
  const { isSomeMenuActive } = useContext(HomeContext);

  const islandWidth = isMobile ? 167 : 259;
  const islandHeight = isMobile ? 137 : 212;
  const data = [
    {
      title: "საჯარიმო კამერების რადარი",
      first: true,
      second: false,
      third: false,
    },
    {
      title: "ჯარიმების ფოტო და ვიდეო ჩანაწერი",
      first: true,
      second: false,
      third: false,
    },
    {
      title: "დაჯარიმების ადგიმდებარეობა რუკაზე",
      first: true,
      second: false,
      third: false,
    },
    {
      title: "სიჩქარის ჯარიმების შესახებ ჭკვიანი ინფორმაცია",
      first: true,
      second: false,
      third: false,
    },
    {
      title: "ჯარიმების გადახდის შეხსენება",
      first: true,
      second: true,
      third: false,
    },
    {
      title: "ტექდათვალიერების თარიღის შეხსენება",
      first: true,
      second: true,
      third: false,
    },
    {
      title: "არა საჩქარო ჯარიმების ფილტრაცია",
      first: true,
      second: true,
      third: false,
    },
    {
      title: "ჯარიმების ავტომატური შეგროვება",
      first: true,
      second: true,
      third: false,
    },
    {
      title: "ჯარიმის მუხლის განმარტება",
      first: true,
      second: true,
      third: false,
    },
    {
      title: "ჯარიმის დაფარვის 100% გარანტია",
      first: true,
      second: true,
      third: true,
    },
    {
      title: "საწვავის ფასები",
      first: true,
      second: true,
      third: true,
    },
    {
      title: "გადახდილი ჯარიმების შეჯამება",
      first: true,
      second: true,
      third: true,
    },
    { title: "გადახდების არქივი", first: true, second: true, third: true },
    {
      title: "ჯარიმების მარტივი გადახდა",
      first: true,
      second: true,
      third: true,
    },
    {
      title: "საჯარიმო ვებგვერდების მარტივად შემოწმება",
      first: false,
      second: false,
      third: true,
    },
  ];

  const data2 = [
    {
      title: "თბილისი მერიის მიერ დაკისრებული ჯარიმები",
      first: false,
      second: false,
      third: true,
    },
    {
      title: "ევაკუატორით საჯარიმო სადგომზე მონიშნვისას გაფრთხილება",
      first: false,
      second: false,
      third: true,
    },
  ];

  return (
    <div id="ფასი" className="section4 mb128">
      <FbxLogo />
      <h2 className="medium70-text mb16 text-align-center" ref={elementRef}>
        დაზოგეთ ბორბალოთი
      </h2>
      <h3 className="medium50-text mb16 text-align-center">ძალიან იაფად</h3>
      <p className="regular24-text text-align-center mb128">თვეში 1₾-დან</p>
      <div className="gap32 column mb128" style={{ position: "relative" }}>
        <div
          id="sticky"
          className="mw1400 grid sticky"
          style={{
            zIndex: isMobile ? (isSomeMenuActive ? 1 : 3) : 0,
          }}
        >
          {!isMobile && <div />}
          <div className="column ai-center gap8">
            <div className="row">
              <img src={StarSvg} width="26px" height="26px" alt="star" />
              <p className="medium24-text mb8">&nbsp;სრული</p>
            </div>
            <p className="regular24-text text-align-center">თვეში 2₾</p>
          </div>
          <div className="column ai-center gap8">
            <div className="row">
              <img src={RudderSvg} width="26px" height="26px" alt="star" />
              <p className="medium24-text mb8">&nbsp;საბაზისო</p>
            </div>
            <p className="regular24-text text-align-center">თვეში 1₾</p>
          </div>
          <div className="column ai-center gap8">
            <div className="row">
              <p className="medium24-text mb8">შეზღუდული</p>
            </div>
            <p className="regular24-text text-align-center">უფასო</p>
          </div>
        </div>
        <div className="mw1400 grid">
          {data.map(item => (
            <React.Fragment key={item.title}>
              <p className="medium26-text section4-text">{item.title}</p>
              <div className="check-wrapper">
                {item.first && (
                  <img
                    src={CheckSvg}
                    width="20px"
                    height="24px"
                    alt="round-logo"
                  />
                )}
              </div>
              <div className="check-wrapper">
                {item.second && (
                  <img
                    src={CheckGreySvg}
                    width="20px"
                    height="24px"
                    alt="round-logo"
                  />
                )}
              </div>
              <div className="check-wrapper">
                {item.third && (
                  <img
                    src={CheckGreySvg}
                    width="20px"
                    height="24px"
                    alt="round-logo"
                  />
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="w100 mw1400">
        <h3 className="medium38-text mb32 ">ფასი მოიცავს შემდეგ უწყებებს</h3>
        <div className="row flex-wrap jc-space-between gap32 mb64">
          <div className="column ai-center">
            <img
              src={require("./assets/section4/island1.png")}
              width={islandWidth}
              height={islandHeight}
              alt="island-1"
              className="mb32"
            />
            <p className="medium26-text text-align-center">შსს</p>
          </div>
          <div className="column ai-center">
            <img
              src={require("./assets/section4/island2.png")}
              width={islandWidth}
              height={islandHeight}
              alt="island-2"
              className="mb32"
            />
            <p className="medium26-text text-align-center">ქუთაისი</p>
          </div>
          <div className="column ai-center">
            <img
              src={require("./assets/section4/island3.png")}
              width={islandWidth}
              height={islandHeight}
              alt="island-3"
              className="mb32"
            />
            <p className="medium26-text text-align-center">გორი</p>
          </div>
        </div>

        <h3 className="medium38-text mb32">
          ზოგიერთი უწყება ითხოვს დამატებით გადასახადს
        </h3>
        <div className="row flex-wrap jc-space-between gap32 mb64">
          <div className="column ai-center">
            <img
              src={require("./assets/section4/island4.png")}
              width={islandWidth}
              height={islandHeight}
              alt="island-4"
              className="mb32"
            />
            <p className="medium26-text text-align-center">თბილისის მერია</p>
          </div>
        </div>
      </div>
      <div className="mw1400 grid2">
        <div className="mb32" />
        {isMobile && <div className="mb32" />}
        <div className="column ai-center gap8 mb32">
          <div className="row">
            <p className="medium24-text mb8">თბილისი</p>
          </div>
          <p className="regular24-text text-align-center">+ 1₾ თვეში</p>
        </div>
        {data2.map(item => (
          <React.Fragment key={item.title}>
            <p className="medium26-text section4-text">{item.title}</p>
            {isMobile && (
              <>
                <div className="check-wrapper" />
                <div className="check-wrapper" />
              </>
            )}
            <div className="check-wrapper">
              {item.third && (
                <img
                  src={CheckSvg}
                  width="20px"
                  height="24px"
                  alt="round-logo"
                />
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default Section4;
