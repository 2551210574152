import QrComponent from "pages/Home/QrComponent";
import "./styles.scss";
import LogoSvg from "assets/icons/logo.svg";
import useIsVisible from "hooks/useIsVisible";

function Section5() {
  const { elementRef } = useIsVisible("ჩაწერა");
  return (
    <div id="ჩაწერა" className="section5 mb128" ref={elementRef}>
      <img
        src={LogoSvg}
        width="77px"
        height="77px"
        alt="logo"
        className="mb32"
      />
      <h2 className="medium50-text mb16 color-black text-align-center">
        ბორბალო — აპლიკაცია
        <br />
        რომელსაც შეგიძლიათ ენდოთ
      </h2>
      <p className="regular20-text color-black text-align-center mb32">
        შეურეთდით ათი ათასობით მძღოლს, რომელიც ყოველდღიურად იყენებს ბორბალოს
      </p>
      <QrComponent isMobileButton={false} />
    </div>
  );
}

export default Section5;
