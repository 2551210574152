import useIsVisible from "hooks/useIsVisible";
import "./styles.scss";

function Section2() {
  const { elementRef } = useIsVisible("გუნდი");
  return (
    <div className="second-section">
      <div
        className="row-column jc-space-between gap32 mb32 border-bottom w100"
        style={{
          maxWidth: 1664,
        }}
      >
        <p className="medium32-text color-black" id="გუნდი" ref={elementRef}>
          ჩვენი გუნდი
          <span className="color-grey">
            . მსოფლიოს სხვადასხვა კუთხეშია, ერთად — ბორბალოა
          </span>
        </p>
        <p className="medium32-text color-black mw400">დაფუძნებელი</p>
      </div>
      <div className="row ai-center jc-space-between gap32">
        <div className="flex1">
          <img
            src={require("./assets/map.png")}
            width="100%"
            alt="right"
            style={{
              objectFit: "contain",
            }}
          />
        </div>
        <div className="column mw400 section2-wrapper">
          <p className="meduim18-text mb8">დაფუძნებელი და დირექტორი</p>
          <p className="medium32-text">კობა ჟღენტი</p>
        </div>
      </div>
    </div>
  );
}

export default Section2;
