import { useState } from "react";
import { HomeContext } from "contexts";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "pages/PrivacyPolicy";
import RouteWithNavBar, { navBar } from "RouteWithNavBar";
import Home from "pages/Home";
import "@fontsource/firago/400-italic.css";
import "@fontsource/firago/400.css";
import "@fontsource/firago/500.css";
import "@fontsource/firago/600.css";
import "@fontsource/firago/700.css";
import "@fontsource/firago/700-italic.css";
import Guideline from "pages/Guideline";
import TermsOfUse from "pages/TermsOfUse";
import Eula from "pages/Eula";

function App() {
  const [activeId, setActiveId] = useState("");
  const [isSomeMenuActive, setIsSomeMenuActive] = useState<boolean>(false);

  const changeActiveId = (id: string) => {
    setActiveId(id);
  };

  const changeIsSomeMenuActive = (value: boolean) => {
    setIsSomeMenuActive(value);
  };
  return (
    <HomeContext.Provider
      value={{
        activeId,
        changeActiveId,
        isSomeMenuActive,
        changeIsSomeMenuActive,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/design_guidelines_en" element={<Guideline isEn />} />
          <Route
            path="/design_guidelines_ge"
            element={<Guideline isEn={false} />}
          />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/eula" element={<Eula />} />
          <Route element={<RouteWithNavBar />}>
            <Route path="/" element={<Home />} />
            {navBar.map(item => (
              <Route
                key={item.path}
                path={item.path}
                element={item.Element ?? <div></div>}
              />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
    </HomeContext.Provider>
  );
}

export default App;
