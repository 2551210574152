import { Outlet } from "react-router-dom";
import FacebookSvg from "./assets/icons/facebook.svg";
import TiktokSvg from "./assets/icons/tiktok.svg";
import YoutubeSvg from "./assets/icons/youtube.svg";

import "./styles.scss";
import clsx from "clsx";
import QrComponent from "pages/Home/QrComponent";
import Home from "pages/Home";
import Other from "pages/Other";
import { Header } from "Header";
import useIsVisible from "hooks/useIsVisible";

export const navBar = [
  {
    path: "/",
    label: "ბორბალო",
    Element: <Home />,
  },
  {
    path: "/other",
    label: "ჯადოსნური ბარათი",
    Element: <Other />,
  },
  // {
  //   path: "/club",
  //   label: "კლუბი",
  //   Element: <div></div>,
  // },
  // {
  //   path: "/business",
  //   label: "ბორბალო ბიზნესისთვის",
  //   Element: <div></div>,
  // },
  // {
  //   path: "/other",
  //   label: "სხვა",
  //   Element: <div></div>,
  // },
];

const RouteWithNavBar = () => {
  // TODO: change this
  const id = window.location.href.includes("other") ? "ჩაწერა" : "ჩაწერა";

  const { elementRef } = useIsVisible(id);

  const texts1 = [
    {
      text: "აპლიკაცია",
      className: "regular18-text color-grey",
      link: undefined,
    },
    {
      text: "კონფიდენციალურობა",
      className: "medium18-text",
      link: "/privacy-policy",
    },
    {
      text: "პირობები",
      className: "medium18-text",
      link: "/terms-of-use",
    },
    {
      text: "სალიცენზიო",
      className: "medium18-text",
      link: "/eula",
    },
  ];

  const texts2 = [
    {
      text: "კომპანია",
      className: "regular18-text color-grey",
    },
    {
      text: "ჩვენს შესახებ",
      className: "medium18-text",
    },
    {
      text: "დაკავშირება",
      className: "medium18-text",
    },
    {
      text: "მშობელი კომპანია",
      className: "medium18-text",
    },
  ];

  return (
    <>
      <Header />
      <Outlet />
      <footer>
        <div className="row flex-wrap jc-space-between gap32 mb64 w100">
          <div className="row gap32">
            <img
              src={require("./assets/icons/logo.jpg")}
              alt="footer-logo"
              width={126}
              height={126}
              className="footer-logo"
            />
            <h2
              id={id}
              className="medium50-text color-grey mw415"
              ref={elementRef}
            >
              დაზოგეთ თანხა მარტივად
            </h2>
          </div>
          <QrComponent isMobileButton={true} />
        </div>
        <div className="row flex-wrap jc-space-between gap32 flex1 mb64">
          <div className="row flex1 gap32 flex-wrap">
            <div className="column flex1">
              {texts1.map(text =>
                text.link ? (
                  <a
                    href={text.link}
                    target="_blank"
                    rel="noreferrer"
                    key={text.text}
                  >
                    <p
                      className={clsx([text.className, "mb16"])}
                      key={text.text}
                    >
                      {text.text}
                    </p>
                  </a>
                ) : (
                  <p className={clsx([text.className, "mb16"])} key={text.text}>
                    {text.text}
                  </p>
                ),
              )}
            </div>
            <div className="column flex1">
              {texts2.map(text => (
                <p className={clsx([text.className, "mb16"])} key={text.text}>
                  {text.text}
                </p>
              ))}
            </div>
          </div>
          <div className="row flex1 gap32 flex-wrap ">
            <div className="column flex1 ai-flex-end">
              <p className="regular18-text color-grey mb16">გამოგვყევით</p>
              <div className="row gap16">
                <a href="https://www.facebook.com/Borbaloapp">
                  <img
                    src={FacebookSvg}
                    alt="facebook"
                    width={25}
                    height={25}
                  />
                </a>
                <a href="https://www.tiktok.com/@borbaloapp">
                  <img src={TiktokSvg} alt="tiktok" width={25} height={25} />
                </a>
                <a href="https://www.youtube.com/@borbalo">
                  <img src={YoutubeSvg} alt="youtube" width={25} height={25} />
                </a>
              </div>
            </div>
            <div className="column flex1 ai-flex-end">
              <div className="mw185">
                <p className="regular18-text color-grey mb16">ცხელი ხაზი</p>
                <a href="tel:+995322055015" className="medium18-text mb4">
                  +995 32 2 05 50 15
                </a>
                <p className="regular16-text color-grey mb16">
                  ორშ. - შაბ.
                  <br />
                  11:00 - 18:00 • თბილისი
                </p>
                <a
                  href="mailto:kitxvebi@borbalo.ge"
                  className="medium18-text mb4"
                >
                  kitxvebi@borbalo.ge
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row flex-wrap jc-space-between gap32 mw1400 flex1">
          <p className="regular14-text color-grey mb16">© 2024 „კჟ ჯგუფი“</p>
        </div>
      </footer>
    </>
  );
};

export default RouteWithNavBar;
