export const cards1 = [
  {
    icon: require("./assets/cards1/icon1.svg").default,
    backgroundIcon: require("./assets/cards1/bg1.png"),
    backgroundIconColor: "#fff",
    title: "შეხსენებები",
    description:
      "„ბორბალო“ გამოგიგზავნით შეტყობინებებს ფასდაკლების ამოწურვისას და ჯარიმის საურავის გაზრდამდე.",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "სტანდარტი",
    footerText: "ბორბალოს სტანდარტი",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards1/icon2.svg").default,
    backgroundIcon: require("./assets/cards1/bg2.png"),
    backgroundIconColor: "#fff",
    title: "ინფორმაცია",
    description:
      "გაიგეთ თქვენი ჯარიმის შესახებ მეტი „ბორბალოში“. მაგალითად, ნახეთ რა სიჩქარე იყო დაშვებული და რადემნით მოძრაობდით თქვენ",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/star.svg").default,
    footerTitle: "სრული",
    footerText: "გეგმა „სრულის“ ექსკლუზივი",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards1/icon3.svg").default,
    backgroundIcon: require("./assets/cards1/bg3.png"),
    backgroundIconColor: "#00C6BE",
    title: "გადახდა",
    description:
      "„ბორაბლოდან“ უმარტივესად შეგიძლიათ რამოდენიმე ჯარიმის ერთად დაფარვა თქვენი დამახსოვრებული ბარათიდან",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "სტანდარტი",
    footerText: "ბორბალოს სტანდარტი",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards1/icon4.svg").default,
    backgroundIcon: require("./assets/cards1/bg4.png"),
    backgroundIconColor: "rgba(0,0,0,.46)",
    title: "არა საჩქარო ჯარიმები",
    titleFontSize: 22,
    description:
      "„ბორბალო“ ამოიცნობს ჯარიმებს, რომლეთა გადახდის ვალდებულაც არ გაქვთ* და „არა საჩქაროდ“ მონიშნავს. ისინი შეგიძლიათ არ გადახაიდოთ",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/rudder.svg").default,
    footerTitle: "საბაზისო",
    footerText: "სტანდარტული ბორბალოს მომსახურება",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards1/icon5.svg").default,
    backgroundIcon: require("./assets/cards1/bg5.svg").default,
    backgroundIconColor: "#fff",
    title: "დაჯარიმების ადგილი",
    titleFontSize: 23,
    description:
      "მხოლოდ „ბორბალოშია“ შესაძლებელი დაჯარიმების ზუსტი ადგილის ნახვა რუკაზე. გაიხსენეთ თქვენი ჯარიმა მარტივად",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "ექსკლუზივი",
    footerText: "მხოლოდ ბორბალოში",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards1/icon6.svg").default,
    backgroundIcon: require("./assets/cards1/bg6.png"),
    backgroundIconColor: "#fff",
    title: "შეჯამება",
    description:
      "მანქანის გვერდიდან შეგიძლიათ ნახოთ რამდენი ლარის ჯარიმებს იხდით ყოველთვიურად და ყოველწლიურად",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/ok2.svg").default,
    footerTitle: "სტანდარტი",
    footerText: "ბორბალოს სტანდარტი",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards1/icon7.svg").default,
    backgroundIcon: require("./assets/cards1/bg7.png"),
    backgroundIconColor: "#00C6BE",
    title: "გარანტია",
    description:
      "„ბორბალოდან“ გადახდილ ჯარიმებს აქვთ 100% დაფარვის გარანტია. გადახდიდან მომენტალურად თანხა ირიცხება შესაბის უწყებაში.",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "სტანდარტი",
    footerText: "ბორბალოს სტანდარტი",
    textColor: "#000",
    textFooterColor: "#000",
  },
];

export const cards2 = [
  {
    icon: require("./assets/cards2/icon1.svg").default,
    backgroundIcon: require("./assets/cards2/bg1.png"),
    backgroundIconColor: "#fff",
    title: "რადარი",
    description:
      "რადარი არის თქვენი თანამგზავრი, რომელიც გაგაფრთხილებთ საჯარიმო კამერებთან მიახლოებას და დაგეხმარებათ ნავიგაციაში",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/star.svg").default,
    footerTitle: "სრული",
    footerText: "გეგმა „სრულის“ ექსკლუზივი",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards2/icon2.svg").default,
    backgroundIcon: require("./assets/cards2/bg2.png"),
    backgroundIconColor: "#fff",
    title: "საწვავი",
    description:
      "ნახეთ და შეადარეთ ყოველდღიურად განახლებული საწვავის ფასები ქვეყანაში და მიიღეთ ინფორმირებული გადაწყვეტილება",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "საბაზისო",
    footerText: "სტანდარტული ბორბალოს მომსახურება",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards2/icon3.svg").default,
    backgroundIcon: require("./assets/cards2/bg3.svg").default,
    backgroundIconColor: "#fff",
    title: "ტექდათვალიერება",
    titleFontSize: 25,
    description:
      "თქვენი მანქანისთვის სავალდებულო პერიოდული ტექდათვალიერების გავლას „ბორბალო“ დროულად შეგახსენებთ. ",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/rudder.svg").default,
    footerTitle: "საბაზისო",
    footerText: "სტანდარტული ბორბალოს მომსახურება",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards2/icon4.svg").default,
    backgroundIcon: require("./assets/cards2/bg4.png"),
    backgroundIconColor: "#00C6BE",
    title: "ავტომატური გადახდები",
    titleFontSize: 21,
    description:
      "ავტომატური გადაახდების ჭკვიანი სისტემაა, რომელიც თქვენს ნაცვლად ჯარიმებს საუკეთესო დროს გადაიხდის – ჩართეთ და არ დაიდარდოთ",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/plus-white.svg").default,
    footerTitle: "დამატებითი",
    footerText: "საჭიროებს ცალკეულ გადასახადს",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
  {
    icon: require("./assets/cards2/icon5.svg").default,
    backgroundIcon: require("./assets/cards2/bg5.svg").default,
    backgroundIconColor: "#fff",
    title: "გადაყვანის გაფრხილება",
    titleFontSize: 20,
    description:
      "თბილისში „ბორბალოს“ შეუძლია შეგატყობინოთ თუ თქვენი მანქანა მონიშნება ევაკუატორით საჯარიმო სადგომზე გადასაყვანად",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/plus.svg").default,
    footerTitle: "დამატებითი",
    footerText: "საჭიროებს ცალკეულ გადასახადს",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards2/icon6.svg").default,
    backgroundIcon: require("./assets/cards2/bg6.png"),
    backgroundIconColor: "#fff",
    title: "პარკირების საშვი",
    titleFontSize: 28,
    description:
      "მარტივად შეიძინეთ პარკირების საშვები „ბორბალოდან“. საშვები ხელმისაწვდომია საქართველოს ქალაქების უმრავლესობისთვის!",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/dots.svg").default,
    footerTitle: "მომსახურება",
    footerText: "შემოთავაზებული მომსახურება",
    textColor: "#000",
    textFooterColor: "#000",
  },
];

export const cards3 = [
  {
    icon: require("./assets/cards3/icon1.svg").default,
    backgroundIcon: require("./assets/cards3/bg1.svg").default,
    backgroundIconColor: "#fff",
    title: "უფასო ვერსია",
    description:
      "შეამოწმეთ პოლციის და სხვა უწყებების ვებგვერდები ყველაზე მარტივად და გადაიხადეთ ჯარიმები დამახსოვრებული ბარათით",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "სტანდარტი",
    footerText: "ბორბალოს სტანდარტი",
    textColor: "#000",
    textFooterColor: "#000",
  },
  // {
  //   icon: require("./assets/cards3/icon2.svg").default,
  //   backgroundIcon: require("./assets/cards3/bg2.svg").default,
  //   backgroundIconColor: "#fff",
  //   title: "CarPlay",
  //   titleFontSize: 29,
  //   description:
  //     "„ბორბალოს“ აქვს „CarPlay-ს“ ინტეგრაცია. ადევენეთ თვალი საჯარიმო კამერების რადარის მანქანის ენკრანიდან",
  //   buttonText: "გახსნა",
  //   isButtonBlack: true,
  //   footerIcon: require("./assets/ok-white.svg").default,
  //   footerTitle: "სტანდარტი",
  //   footerText: "ბორბალოს სტანდარტი",
  //   textColor: "#000",
  //   textFooterColor: "#fff",
  // },
  {
    icon: require("./assets/cards3/icon3.svg").default,
    backgroundIcon: require("./assets/cards3/bg3.svg").default,
    backgroundIconColor: "#FFBA00",
    title: "განმარტებული მიზეზი",
    titleFontSize: 22,
    description:
      "„ბორბალო“ იურიდიულმა გუნდმა სათითაოდ განმარტა ყველა მუხლი, რადგან მარტივი ენით ნახოთ დაჯარიმების მიზეზი",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "სტანდარტი",
    footerText: "ბორბალოს სტანდარტი",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards3/icon4.svg").default,
    backgroundIcon: require("./assets/cards3/bg4.png"),
    backgroundIconColor: "#000",
    title: "თქვენი 3D მანქანა",
    titleFontSize: 27,
    description:
      "დაასკანერეთ ტექპასპორტის უკანა მხარე და ნახეთ თქვენი მანქანის 3D მოდელი. შესაბამისი ფერით და ასაკობრივი მოდელით",
    buttonText: "გახსნა",
    isButtonBlack: false,
    footerIcon: require("./assets/ok-white.svg").default,
    footerTitle: "სტანდარტი",
    footerText: "ბორბალოს სტანდარტი",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
  {
    icon: require("./assets/cards3/icon5.svg").default,
    backgroundIcon: require("./assets/cards3/bg5.png"),
    backgroundIconColor: "#000",
    title: "წვლილის შეტანა",
    titleFontSize: 29,
    description:
      "შეიტანეთ წვილი „ბორბალოს“ რუკაში. მაგალითად დააფიქსირეთ თუ დაინახავთ კამერას, რომელიც ჩვენს ბაზაში არაა",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "სტანდარტი",
    footerText: "ბორბალოს სტანდარტი",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards3/icon6.svg").default,
    backgroundIcon: require("./assets/cards3/bg6.png"),
    backgroundIconColor: "#00C6BE",
    title: "ღირშესანიშნაობები",
    titleFontSize: 25,
    description:
      "„ბორბალო“ ქმნის ულამაზეს რუკას თქვენთვის. ჩვენი 3D ქალაქის ღირშესანიშნაოები ამშვენებს რუკას და გეხმარებათ ნავიგაციაში. ",
    buttonText: "გახსნა",
    isButtonBlack: false,
    footerIcon: require("./assets/ok-white.svg").default,
    footerTitle: "სტანდარტი",
    footerText: "ბორბალოს სტანდარტი",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
  {
    icon: require("./assets/cards3/icon7.svg").default,
    backgroundIcon: require("./assets/cards3/bg7.png"),
    backgroundIconColor: "#fff",
    title: "მარტივი დამატება",
    titleFontSize: 27,
    description:
      "„ბორბალოში“ მანქანის დამატება უმარტივესია. უბრალოდ აჩვენეთ ტექპასპორტი კამერას და ჩვენი სიტემა თავად შეიყვანს ყველა მონაცემს",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "სტანდარტი",
    footerText: "ბორბალოს სტანდარტი",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards3/icon8.svg").default,
    backgroundIcon: require("./assets/cards3/bg8.png"),
    backgroundIconColor: "#fff",
    title: "თარგმნა",
    description:
      "„ბორბალო“ ნათარგმნია ინგლისურ ენაზე, რაც არაქართულენოვან მოლაპარაკეებს ეხმარება მათი დაჯარიმების მიზეზების გარკვევაში",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "სტანდარტი",
    footerText: "ბორბალოს სტანდარტი",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards3/icon9.svg").default,
    backgroundIcon: require("./assets/cards3/bg9.png"),
    backgroundIconColor: "#fff",
    title: "მუქი იერი",
    description:
      "„ბორბალო“ გთავაზობთ ულამაზეს მუქ იერს. ის აუმჯობესებს მომხმარებლის გამოცდილებას თავისი ელეგანტური და დამამშვიდებელი დიზაინით",
    buttonText: "გახსნა",
    isButtonBlack: false,
    footerIcon: require("./assets/ok-white.svg").default,
    footerTitle: "სტანდარტი",
    footerText: "ბორბალოს სტანდარტი",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
  {
    icon: require("./assets/cards3/icon10.svg").default,
    backgroundIcon: require("./assets/cards3/bg10.svg").default,
    backgroundIconColor: "#fff",
    title: "არქივი",
    description:
      "ყველა თქვენს მიერ გაკეთებული გადარიცხვა სანდოდ შეინახება არქივის გვერდზე. რათა საჭიროების შემთხვევაში შეძლოთ მათი ნახვა.",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "სტანდარტი",
    footerText: "ბორბალოს სტანდარტი",
    textColor: "#000",
    textFooterColor: "#000",
  },
];

export const cards4 = [
  {
    icon: require("./assets/cards4/icon1.svg").default,
    backgroundIcon: require("./assets/cards4/bg1.png"),
    backgroundIconColor: "#fff",
    title: "შსს",
    description:
      "ჩვენი უპირველესი პარტნიორი, საქართველოს შინაგან საქმეთა სამინისტრო. ნახეთ და გადაიხადეთ შსს მიერ დაწესებული ჯარიმები",
    buttonText: "გახსნა",
    isButtonBlack: true,
    footerIcon: require("./assets/rudder.svg").default,
    footerTitle: "საბაზისო",
    footerText: "დამატებითი გადასახადის გარეშე",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards4/icon2.svg").default,
    backgroundIcon: require("./assets/cards4/bg2.png"),
    backgroundIconColor: "#fff",
    title: "თბილისი",
    description:
      "დედაქალებისთვის, აკონტროლეთ ჯარიმები და მიიღეთ შეტყობინება თუ თქვენი მანქანა მოინიშნება ევაკუატორით გადასაყვანად",
    buttonText: "გახსნა",
    isButtonBlack: false,
    footerIcon: require("./assets/plus-white.svg").default,
    footerTitle: "დამატებითი",
    footerText: "საჭიროებს ცალკეულ გადასახადს",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
  {
    icon: require("./assets/cards4/icon3.svg").default,
    backgroundIcon: require("./assets/cards4/bg3.png"),
    backgroundIconColor: "#fff",
    title: "ქუთაისი",
    description:
      "იყოდ და არა იყო, რა ღვთის უკეთესი რა იქნებოდა. იყო ჩიტი მგალობელი და ღმერთი მწყალობელი.   •   ჭირი იქა ხლინი აქა. ქატო იქა ფქვილი აქა.",
    buttonText: "გახსნა",
    isButtonBlack: false,
    footerIcon: require("./assets/rudder-white.svg").default,
    footerTitle: "საბაზისო",
    footerText: "სტანდარტული ბორბალოს მომსახურება",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
  {
    icon: require("./assets/cards4/icon4.svg").default,
    backgroundIcon: require("./assets/cards4/bg4.png"),
    backgroundIconColor: "#fff",
    title: "გორი",
    titleFontSize: 32,
    description:
      "იყოდ და არა იყო, რა ღვთის უკეთესი რა იქნებოდა. იყო ჩიტი მგალობელი და ღმერთი მწყალობელი.   •   ჭირი იქა ხლინი აქა. ქატო იქა ფქვილი აქა.",
    buttonText: "გახსნა",
    isButtonBlack: false,
    footerIcon: require("./assets/rudder-white.svg").default,
    footerTitle: "საბაზისო",
    footerText: "სტანდარტული ბორბალოს მომსახურება",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
];
