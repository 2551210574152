import "./styles.scss";
import block1Svg from "./assets/section3/block1.svg";
import block2Svg from "./assets/section3/block2.svg";

function Section3() {
  const data = [
    {
      icon: block1Svg,
      title: "შეუდარებელი დაცულობა",
      description:
        "„ბორბალო“ გულმდგინედ იცავს თქვენს მონაცემებს და იყენებს მხოლოდ სანდო წყაროებს თქვენი ინფორმაციის დაცულობისთვის.",
    },
    {
      icon: block2Svg,
      title: "ერთი მიზანი",
      description:
        "„ბორბალო“ შექმნილია იმისათვის, რომ გაუმარტივოს მძღოლების ყოველდღიურობა და მინიმუმამდე დაიყვანოს არასასურველი ხარჯები",
    },
  ];

  return (
    <div className="third-section gap32">
      {data.map(item => (
        <div className="block" key={item.title}>
          <img
            src={item.icon}
            width="82px"
            height="95px"
            alt="block1"
            className="mb16"
          />
          <h2 className="medium46-text mb16 color-white text-align-center">
            {item.title}
          </h2>
          <p className="regular18-text color-white text-align-center block-description mb32">
            {item.description}
          </p>
          <div className="row">
            <div className="block-line" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Section3;
