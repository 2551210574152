import "./styles.scss";
import Quotes1Svg from "./assets/quotes1.svg";
import Quotes2Svg from "./assets/quotes2.svg";
import useIsVisible from "hooks/useIsVisible";

function Section3() {
  const { elementRef } = useIsVisible("ისტორია");
  return (
    <div className="third-section">
      <div className="mw1400 w100">
        <p
          className="medium32-text color-black border-bottom mb32"
          id="ისტორია"
          ref={elementRef}
        >
          შექმნის ისტორია
          <span className="color-grey">. ის რაც ყველას შემთხვევია</span>
        </p>
        <p className="regular28-text mb64">
          ჩვენი დაფუძნების ისტორია უკავშირდება ყველასთვის ნაცნობ შემთხვევას —
          დავიწყებულ ჯარიმას. რომლის საწყისი საურავი რამოდენიმეჯერ იზრდება.
          სწორედ, ასეთმა „ძვირადღირებულმა დავიწყებამ“, რომელიც სხვათაშორის ჩვენი
          დამფუძლებლის მამას, ასევე ჩვენს პირველ ინვესტორს, შეემთხვა დაბადა
          „ბორბალოს“ გაჩენის იდეა.
        </p>
        <p className="medium32-text color-black border-bottom mb32">
          სახელის წარმოშობა
          <span className="color-grey">. ქართული წიაღიდან</span>
        </p>
        <div className="row-column gap64 ai-center">
          <div className="mw400 flex1 section3-text-wrapper column">
            <div className="bg-white p32">
              <img
                src={Quotes1Svg}
                width={36}
                height={34}
                alt="quotes1"
                className="mb16"
              />
              <p className="regular24-text">
                გადმოდგა <strong>ბორბალაზედა</strong> თავისუფლების მთვარეა…
              </p>
            </div>
            <div className="p32 row jc-space-between ai-flex-end">
              <div>
                <p className="regular24-text mb8">
                  <strong>ვაჟა ფშაველა</strong>
                  <span className="color-grey"> 1892 წ.</span>
                </p>
                <p className="regular18-text">ამონარიდი „ბახტრიონიდან“</p>
              </div>
              <img src={Quotes2Svg} width={36} height={34} alt="quotes2" />
            </div>
          </div>
          <p className="regular28-text flex1">
            სახელწოდება „ბორბალო“ შთაგონებულია ვაჟა ფშაველას ლიტერატურული
            სამყაროს ღრმა ეთოსიდან, ქართულ სიტყვა — „ბორბალთან“ ერთად.
            <br />
            <br />
            ეს შერწყმა არა მხოლოდ პატივს სცემს ჩვენს მდიდარ კულტურულ
            მემკვიდრეობას, არამედ ასახავს აპლიკაციის არსს და ჩვენს ვალდებულებას,
            გადავლახოთ დაბრკოლებები ინოვაციური გადაწყვეტილებებით.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Section3;
