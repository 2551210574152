import "./styles.scss";
import Section1LogosSvg from "./assets/section1-logos.svg";

function Section1() {
  return (
    <>
      <div className="first-section mb64">
        <div className="mw1400 w100">
          <img
            src={Section1LogosSvg}
            width="249px"
            height="128px"
            alt="right"
            className="mb64"
          />
          <div className="row jc-space-between flex-wrap gap32">
            <p className="regular32-text color-white">
              ვქმნით : ბრწყინვალეს : ქართულს ჻
            </p>
            <p className="regular32-text color-white">კჟ ჯგუფი</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section1;
